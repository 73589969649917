<template>
  <div id="app">
    <!-- <BackgroundComponent /> -->
    <TopNav />
    
    <!-- <BottomNav :view_state="current_view"/> -->
    <!-- <transition name="view-transition">
      <keep-alive>
        <router-view />
      </keep-alive>
    </transition> -->
    <div id="bottom-coming-soon-bar"><h1 id="bottom-bar-heading"></h1></div>
  </div>
</template>

<script>
  // import BottomNav from '@/components/BottomNav';
  // import BackgroundComponent from '@/components/BackgroundComponent';
  import TopNav from '@/components/TopNav';

  export default {
    name: "app",
    components: {
      TopNav,
      // BottomNav,
      // BackgroundComponent
    },
    data() {
      return {
        current_view: 'home',
        typewriterText: ' Diese Seite befindet sich im Aufbau...',
        typewriterInt: 0,
        // typewriterSpeed: '1000',
        // globalSiteBg: '',
      }
    },
    mounted(){
      if ( this.typewriterInt === 0 ) {
        this.typeWriter();
      }
    },
    //! ARROW NAVIGATION DISABLED (DURING COMING SOON MODE)
    // created() {
    //   this.changeViewState();
    //   window.addEventListener('keydown', this.arrowNavigation);
    //   if ( this.current_view == 'home' ) {
    //     window.addEventListener('wheel', this.arrowNavigation);
    //   } else {
    //     window.removeEventListener('wheel', this.arrowNavigation);
    //   }
    // },
    // updated() {
    //   this.changeViewState();
    //   if ( this.current_view == 'home' ) {
    //     window.addEventListener('wheel', this.arrowNavigation);
    //   } else {
    //     window.removeEventListener('wheel', this.arrowNavigation);
    //   }
    // },
    // destroyed() {
    //   window.removeEventListener('keydown', this.arrowNavigation);
    // },
    methods: {
      changeViewState() {
        // manage router view
        var viewName = this.$route.fullPath.split('/')[1];
        if ( viewName == '' ) {
          this.current_view = 'home';
        } else if ( viewName == 'about' || viewName.split('#')[0] == 'about' ) {
          this.current_view = 'about';
        } else if ( viewName == 'projekte' || viewName.split('#')[0] == 'projekte' ) {
          this.current_view = 'projekte';
        }

        // manage body class
        document.body.classList.remove('home', 'about', 'projekte');
        if ( viewName == '' ) {
          document.body.classList.add('home');
        } else {
          document.body.classList.add(viewName.split('#')[0]);
        }
      },
      moveHorizontally(direction) {
        if ( direction == 'left') {
          if ( this.current_view == 'home') {
            this.$router.push('about');
          } else if ( this.current_view == 'projekte') {
            this.$router.push('/');
          }
        } else if ( direction == 'right') {
            if ( this.current_view == 'home') {
              this.$router.push('projekte');
            } else if ( this.current_view == 'about') {
              this.$router.push('/');
            }
        } else {
          console.error('Function moveHorizontally received unknown parameter');
        }
      },
      arrowNavigation(e) {
        if ( e.type == 'keydown' ) {
          if ( e.keyCode == 37 ) { // arrow left
            this.moveHorizontally('left');
          } else if ( e.keyCode == 39) { // arrow right
            this.moveHorizontally('right');
          }
        } else if ( e.type == 'wheel' ) {
          if ( e.deltaY > 0 ) {
            this.moveHorizontally('right');
            // window.removeEventListener('wheel', this.arrowNavigation);
            document.body.classList.add('scroll-disabled');
            setTimeout(function(){
              document.body.classList.remove('scroll-disabled');
            }, 1000);
          } else if ( e.deltaY < 0 ) {
            this.moveHorizontally('left');
            // window.removeEventListener('wheel', this.arrowNavigation);
            document.body.classList.add('scroll-disabled');
            setTimeout(function(){
              document.body.classList.remove('scroll-disabled');
            }, 650);
          }
        }
      },
      typeWriter() {
        if (this.typewriterInt < this.typewriterText.length) {
          document.getElementById("bottom-bar-heading").innerHTML += this.typewriterText.charAt(this.typewriterInt);
          this.typewriterInt += 1;
          setTimeout(this.typeWriter, (50 + Math.abs(Math.random() - .5) * 250).toString());
        }
      }
      // changeSiteBg (src) {
      //   this.globalSiteBg = src;
      // }
    }
  }
</script>

<style scoped>
div#bottom-coming-soon-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
}

div#bottom-coming-soon-bar h1 {
    font-size: calc(10px + 4vw);
    text-align: center;
    font-weight: 300;
}
</style>