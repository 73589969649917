<template>
    <div id="top-nav">
      <router-link to="/">
        <img v-if="logoLink" id="logo" :src="logoLink" alt="Logo Thomas Böni Architekten" />
      </router-link>
      <div class="contact-block">
          <p v-html="siteAddress"></p>
          <p v-html="siteContact"></p>
      </div>
    </div>
</template>

<script>
import sanity from '@/sanity';
const blocksToHtml = require('@sanity/block-content-to-html');

const h = blocksToHtml.h
const serializers = {
  types: {
    code: props => (
      h('pre', {className: props.node.language},
        h('code', props.node.code)
      )
    )
  }
}

const query = `*[_type == "globals" ]{
  "logoUrl": logo.asset->url,
  "adresse": addresse,
  "kontakt": kontaktdaten
}[0]`;

export default {
    name: "TopNav",
    data() {
      return {
        logoLink: '',
        siteAddress: '',
        siteContact: ''
      }
    },
    created() {
      sanity.fetch(query).then ( json => {
        this.logoLink = json.logoUrl + '?h=120';
        // this.siteAddress = json.adresse.replace('\n', '<br>');
        this.siteAddress = blocksToHtml({
          blocks: json.adresse,
          serializers: serializers
        });
        // this.siteContact = json.kontakt.replace('\n', '<br>');
        this.siteContact = blocksToHtml({
          blocks: json.kontakt,
          serializers: serializers
        });
      },
      error => {
        this.error = error;
      });
    },
    }
</script>
<style>
div#top-nav {
      position: fixed;
      left: 40px;
      top: 20px;
      width: calc(100% - 80px);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      z-index: 1;
  }
  div#top-nav img {
      max-height: 60px;
      max-width: none;
      margin: 0;
      width: auto;
      object-fit: contain;
  }
div#top-nav .contact-block {
    display: flex;
  }
  .contact-block p {
    margin-bottom: 0;
    font-size: 1.6rem;
  }
  .contact-block a {
    font-size: 1.6rem;
  }
  .contact-block a {
    text-decoration: none;
  }
  div#top-nav .contact-block p:not(:last-child) {
    margin-right: 20px;
  }
  @media screen and (max-width: 1079px) {
    div#top-nav {
      flex-wrap: wrap;
      left: 20px;
      width: calc(100% - 40px);
    }
    div#top-nav img#logo {
      width: 275px;
      max-width: 100%;
      height: auto;
      max-height: none;
    }
    div#top-nav .contact-block {
      flex-wrap: wrap;
    }
    #app div#top-nav .contact-block p {
      font-size: 1.4rem;
    }
    #app div#top-nav .contact-block > p {
      margin-top:10px;
    }
    #app div#top-nav .contact-block a {
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: 720px) {
    div#top-nav {
      position: absolute;
    }
  }
  @media screen and (max-width: 374px) {
    div#top-nav .contact-block p:not(:last-child) {
      margin-right: 8px;
    }
  }
</style>